.next-background {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;

  .delay-bar {
    width: 100%;
    height: 0.5rem;
    position: relative;
    background: rgba(17, 17, 17, 0.3);

    &__content {
      width: 1px;
      height: 100%;
      position: absolute;
      background-color: var(--borderColor);
      animation: animate-delay 3s linear;
    }
  }

  p {
    margin: 0 0 2rem 0;
  }

  &__button {
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;

    span {
      display: block;
      margin: 1rem 0;
    }
  }

  @keyframes animate-delay {
    from {
      width: 100%;
    }
    to {
      width: 1px;
    }
  }
}
