.viewer {
    width: 100%;
    height: 100%;

    .sessions {
        width: 100%;
        height: 100%;
        overflow: auto;

        .ratio {
            width: calc(50% - 10px);
            border: 1px solid var(--borderColor);
            padding: 5px;
            margin: 5px;
            display: inline-block
        }

        .session {
            width: 100%;
            height: 100%;
            position: relative;

            .name {
                height: 25px;
                padding: 5px;
                text-align: center;
            }

            .slate {
                height: calc(100% - 25px)
            }
        }
    }
}