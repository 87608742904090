html,
body {
  position: fixed;
  overflow-y: hidden;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  color: var(--textColor);
}

* {
  box-sizing: border-box;
}

.test-cookies {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1000000;
  background: red;
}

.drawer-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.tools {
  padding: 20px;
  height: 100%;

  --buttonSize: 55px;

  .tool {
    width: 100%;
    padding: 20px 0;
    display: flex;
    flex-direction: row;
    align-items: center;

    > div {
      align-self: bottom;
      margin-left: auto;
    }

    .max-drawings {
      display: flex;
      align-items: center;

      .button {
        flex-grow: 1;
      }

      span {
        display: inline-block;
        width: 50px;
        text-align: center;
        font-size: 25px;
      }

      &.disabled {
        pointer-events: none;
        opacity: 0.4;
      }
    }

    .lang {
      align-self: bottom;
      margin-left: auto;
      display: flex;

      .button {
        margin: 0 0 0 10px;
      }
    }
  }
}

.overlay.help {
  --buttonSize: 50px;
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    padding: 0 20%;
    //text-align: justify;

    & .overlay__close {
      margin-top: 0;
    }
  }

  .items {
    .item {
      padding: 1rem;
      display: flex;
      align-items: center;
      margin: 0 0 1rem 0;

      &.no-icon {
        justify-content: center;

        .desc {
          margin: 0;
        }
      }

      .desc {
        margin: 0 0 0 1rem;
      }

      .help-text-button {
        display: inline-flex;
        transform: scale(0.8);
        color: white;
      }
    }
  }

  .close {
    /*
    bottom: 0
    top: auto;
    width: 100%;
    text-align: center;
    padding: 0 0 1rem;
    z-index: 10;
    
    background: var(--backgroundColor);
    margin-top: 2vh;
    font-size: 50px;
    transition: all 0.2s;
    */
  }
}

.end-session {
  padding: 1rem;
  z-index: 10000000000;
  background: red;
  position: fixed;
  position: device-fixed;
  left: 0;
  top: 0;

  button {
    margin-bottom: 2rem;

    &.end-session-button {
      margin-top: 2rem;
    }
  }
}

.single-help-title {
  font-weight: bold;
}
