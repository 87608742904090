.input.select {
    position: relative;
    padding: 5px 0;
    width: 100%;

    .selection {
        width: 100%;
        height: 25px;
        display: flex;
        position: relative;
        align-items: center;
        cursor: pointer;

        .opts {
            margin: 0 10px 0 0;
        }

        .search {
            background: none;
            border: 0;
            outline: none;
            z-index: 1;
            color: white;
            flex: 1;
            min-width: 0;
        }

        .arrow {
            margin-left: auto;
        }

        .searching {
            margin-right: 10px;

            &.active {
                color: var(--activeTextColor)
            }
        }

        &.has-value {
            .search::placeholder {
                color: white;
            }
        }

        &.has-values {

            .values {
                display: flex;
                max-width: 70%;

                .value {
                    display: flex;
                    overflow: hidden;
                    margin: 0 5px 0 0;
                    padding: 2px;
                    font-size: 13px;
                    align-items: center;
                    cursor: pointer;

                    span {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    i {
                        margin: 0 0 0 5px;
                    }

                    &:hover {
                        i {
                            color: var(--hoverTextColor)
                        }
                    }
                }

            }
        }

    }

    .list-container {
        position: absolute;
        left: 0;
        top: 35px;
        width: 100%;
        height: 0;
        overflow: hidden;
        transition: all .2s;
        z-index: 10;
        box-shadow: var(--boxShadow);

        .list {
            background: var(--backgroundColor);

            overflow: auto;
            width: 100%;
            height: 100%;
            padding: 5px;

            .option {
                padding: 15px 0;
                box-shadow: inset 0 -1px 0 0 var(--separatorColor);
                display: flex;
                align-items: center;
                cursor: pointer;
                font-size: 14px;

                --buttonSize: 20px;

                &::before {
                    content: '.';
                    display: inline-block;
                    width: var(--buttonSize);
                    height: var(--buttonSize);
                    background: none;
                    border: 1px solid var(--borderColor);
                    z-index: 1;
                    color: transparent;
                    font-size: var(--buttonSize);
                    text-align: center;
                    line-height: var(--buttonSize);
                    margin: 0 10px 0 5px;
                }

                &.selected {

                    &::before {
                        content: "\2713";
                        color: white;
                    }

                }
            }

            .group {
                padding: 0 20px;


                .name {
                    position: relative;
                    left: -15px;
                    padding: 15px 0 0;
                    font-size: 16px;
                }
            }

            .no-options {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 13px;
            }
        }
    }


    &.open {

        .list-container {
            height: 200px;
        }
    }
}