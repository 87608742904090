@import "App/theme.scss";

.menu {
  position: fixed;
  overflow: hidden;
  background: var(--menuBackgroundColor);
  z-index: 100;

  .bottom,
  .top {
    position: absolute;
    display: flex;
  }

  &.hidable {
    position: fixed;
    transition: all 0.2s;
    z-index: 101;
  }

  .button {
    color: white;
  }

  .button.round {
    --buttonSize: calc(var(--menuSize) - 1rem);
    margin: 1rem;

    @media (min-width: 1024px) {
      --buttonSize: calc(var(--menuSize) - 2rem);
    }
  }
}

@media (orientation: portrait) {
  .menu {
    left: 0;
    bottom: 0;
    width: 100%;
    height: var(--menuSize);

    .top,
    .bottom {
      right: 0;
      top: 0;
    }

    &.hidable {
      transform: translateY(100%);

      &.open {
        transform: translateY(0);
      }
    }

    .button.round {
      margin: 0.5rem 1rem;
    }
  }
}

@media (orientation: landscape) {
  .menu {
    right: 0;
    top: 0;
    height: 100%;
    width: var(--menuSize);

    .top {
      right: 0;
      bottom: 0;
      flex-direction: column;
    }

    .bottom {
      right: 0;
      top: 0;
    }

    &.hidable {
      transform: translateX(100%);

      &.open {
        transform: translateX(0);
      }
    }
  }
}
