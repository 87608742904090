.explanation {
  //position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  flex-direction: column;
  background: var(--backgroundColor);
  color: black;

  &-drawings {
    height: 70%;

    .drawing {
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  &-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-height: 30vh;
  }

  &-commands {
    flex-grow: 1;
    position: relative;
  }

  &-info {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    padding: 0.5rem;
    overflow: auto;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  &-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 0.5rem 0;

    &.disabled {
      pointer-events: none;
    }
  }

  &-action {
    cursor: pointer;
    padding: 1vw !important;
    width: auto !important;

    &.selected {
      color: var(--hoverTextColor);
      border-color: var(--hoverTextColor);
    }
  }

  &-action-navigate {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;

    .explanation-text {
      overflow: auto;
      padding: 0.5rem;
      text-align: justify;
      flex-grow: 1;

      @media (min-width: 1024px) {
        font-size: 1rem;
        text-align: justify;
        padding: 0 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .arrow-left {
      margin-left: 1rem;
    }

    .arrow-right {
      margin-right: 1rem;
    }

    .arrow-left,
    .arrow-right {
      display: flex;
      align-items: center;

      .button {
        transform: scale(0.8);
        color: var(--roundedInputBackgroundColor) !important;
        background: white;
        border: 1px solid var(--roundedInputBackgroundColor);
      }
    }
  }

  &-default {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &._dummy > .close {
    display: none;
  }

  &__actions {
    position: absolute;
    right: 1rem;
    top: 1rem;

    .button {
      margin-bottom: 1rem !important;
      color: white !important;

      @media (orientation: portrait) {
        --buttonSize: calc(var(--menuSize) - 2vh);
        margin: 1vh;
      }

      @media (orientation: landscape) {
        --buttonSize: calc(var(--menuSize) - 2vw);
        margin: 1vw;
      }
    }
  }
}
