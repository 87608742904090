.restitution {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  .scale {
    background: linear-gradient(0, blue 40%, cyan 60%, lime 70%, yellow 80%, red 100%);
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100px;
    width: 30px;
    z-index: 2;
  }

  .slides {
    transform: rotate(-90deg);
  }

  .settings {
    position: absolute;
    right: 0;
    top: 0;
    width: 90%;
    height: 100%;
    background: var(--panelBackgroundColor);
    z-index: 90;
    transition: all 0.2s;
    transform: translateX(100%);
    overflow: auto;
    border-left: 1px solid var(--borderColor);

    &.rendering {
      width: 200px;
    }

    &.open {
      transform: translateX(0);
    }

    .settings-content {
      padding: 10px;
      width: 100%;
      height: 100%;
    }

    .sliders {
      height: 50%;
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 30px 0 0;

      .sl-container {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .label {
          margin: 20px 0 0;
          font-size: 10px;
        }

        .value {
          margin: 0 0 10px;
          font-size: 20px;
        }

        &.disabled {
          pointer-events: none;
          opacity: 0.5;
        }
      }
    }

    .select {
      width: 100% !important;
    }

    .generate {
      width: 100% !important;
    }

    .rendering .value {
      display: flex;
      align-items: center;

      > * {
        flex-grow: 1;
      }
    }

    .survey-settings {
      position: relative;
      border: 1px solid var(--borderColor);
      padding: 20px;

      .label {
        font-size: 15px;
      }

      .title {
        position: absolute;
        font-size: 20px;
        margin: 0;
        padding: 0;
        z-index: 1;
        height: auto;
        left: 20px;
        top: -13px;
      }
    }
  }

  .sessions {
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: row;

    .session {
      padding: 0 1rem;
    }

    /*
    .ratio {
      flex-grow: 1;
      height: 100%;
      border: 1px solid var(--borderColor);
      padding: 3px;
      margin: 5px;
      z-index: 10;
      display: inline-block;

      &.selected {
        border-color: var(--hoverBorderColor);
      }
      
    }
    */

    .session {
      width: 100%;
      height: 100%;

      .name {
        height: 25px;
        padding: 2px;
        text-align: center;
      }

      .slate {
        height: calc(100% - 25px);
      }

      &-container {
        width: 100%;
        height: 100%;
        display: flex;
      }

      &-survey {
        width: 40%;
        font-size: 0.8rem;
        display: none;
      }
    }
  }

  &__disabled {
    opacity: 0;
    pointer-events: none;
  }

  &__end {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-button {
      margin-top: 2rem;
    }
  }

  .close {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    display: none;
  }
}

@media (min-width: 400px) {
  .restitution {
    .settings {
      width: 60%;
    }
  }
}

@media (min-width: 750px) {
  .restitution {
    .settings {
      width: 50%;

      &.rendering {
        width: 300px;
      }
    }
  }
}

@media (min-width: 1000px) {
  .restitution {
    .settings {
      width: 40%;
    }
  }
}

.print {
  .tabs {
    .toolbar {
      display: none;
    }
    .tabs-container {
      height: 100%;
    }
  }

  .menu {
    display: none;
  }

  .restitution {
    padding: 0;

    .sessions {
      overflow: hidden;
    }

    .session {
      &-survey {
        display: block;
      }
    }

    .close {
      display: block;
    }
  }
}
