html {
  --fontFamily: "Verdana", sans-serif;
  --backgroundColor: white;
  --textColor: white;
  --borderColor: #1576bf;
  --boxShadow: 0 6px 10px rgba(0, 0, 0, 0.4);
  --boxShadow-glow: 0 6px 10px rgba(0, 0, 0, 0.4), 1px 0px 5px 1px rgba(108, 212, 202, 0.4);
  --separatorColor: #444;
  --labelColor: #47f3a9;
  --panelBackgroundColor: #2d3035;
  --hoverBorderColor: #90e5f3;
  --hoverTextColor: #90e5f3;
  --activeBorderColor: #d4ffcc;
  --activeTextColor: #36e631;
  --activeBackgroundColor: #54b0fc;
  --warningColor: #fd7070;

  --overlayBackground: black;

  --drawerSelectedDrawingBackgroundColor: rgba(0, 0, 0, 0.5);

  --menuBackgroundColor: black;
  --menuBorderColor: white;

  --inputBackgroundColor: #133558;
  --inputBorderColor: var(--borderColor);

  --roundedInputBackgroundColor: #0c69b5;
  --roundedInputBorderColor: transparent;

  --progressBarBackgroundColor: #808080;

  --surveySelectedColor: #071831;
  --surveyBorderColor: #0f4a79;

  .single,
  .survey,
  .tutorial,
  .end,
  .loading-loader,
  .overlay,
  .layout,
  .restitution,
  .explanation,
  .timeline {
    --backgroundColor: white;
    --textColor: black;
    --borderColor: #1576bf;
    --boxShadow: 0 6px 10px rgba(0, 0, 0, 0.4);
    --boxShadow-glow: 0 6px 10px rgba(0, 0, 0, 0.4), 1px 0px 5px 1px rgba(108, 212, 202, 0.4);
    --separatorColor: #444;
    --labelColor: #1c86c4;
    --panelBackgroundColor: white;
    --hoverBorderColor: #38aaf7;
    --hoverTextColor: #1c86c4;
    --activeBorderColor: #d4ffcc;
    --activeTextColor: #36e631;
    --warningColor: #fd7070;

    --overlayBackground: white;

    --drawerSelectedDrawingBackgroundColor: rgba(0, 0, 0, 0.5);

    --menuBackgroundColor: white;
    --menuBorderColor: black;

    --inputBackgroundColor: white; //#133558;
    --inputBorderColor: var(--borderColor);

    --roundedInputBackgroundColor: #0c69b5;
    --roundedInputBorderColor: transparent;

    --progressBarBackgroundColor: #808080;

    --surveySelectedColor: #adc5e7;
    --surveyBorderColor: #0f4a79;
  }
}

@media (orientation: portrait) {
  html {
    --menuSize: 10vh;
  }
}

@media (orientation: portrait) and (min-height: 768px) {
  html {
    --menuSize: 80px;
  }
}

@media (orientation: landscape) {
  html {
    --menuSize: 10vw;
  }
}

@media (orientation: landscape) and (min-width: 768px) {
  html {
    --menuSize: 80px;
  }
}
