.ratio {
    .ratio-container {
        width: 100%;
        padding-top: 100%;
        position: relative;
    }

    .ratio-square {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
}