.information {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  position: fixed;
  background: white;
  z-index: 100000;
  font-size: 1rem;

  span {
    font-size: 1rem;
    margin: 0 0 25px;
    //text-align: center;
    text-align: justify;

    @media (min-width: 1024px) {
      margin: 0px 20% 20px;
    }
  }
}
